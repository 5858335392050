// File to house the global authentication ref of firebase.

import firebase from 'firebase/compat/app';
import mainFirebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import handleAuthError from './utils/handleAuthError';
import firebasePrimaryApp from './index';

const auth = firebase.auth();

// Providers
let googleProvider = new mainFirebase.auth.GoogleAuthProvider();

export default auth;
export const providers = { googleProvider, mainFirebase };

export const getToken = async () => {
	if (auth.currentUser) {
		let token = await auth.currentUser.getIdToken(true);
		return token;
	} else return '';
};
export const loginWithGoogle = async (callback) => {
	try {
		if (!auth.currentUser) {
			await auth.signInWithPopup(providers.googleProvider);
			return callback(null);
		} else return callback('User already signed in.');
	} catch (err) {
		console.log(handleAuthError(err));
		return callback(handleAuthError(err));
	}
};

export const logout = async (callback) => {
	try {
		await auth.signOut();
		return callback(null);
	} catch (err) {
		console.log(handleAuthError(err));
		return callback(handleAuthError(err));
	}
};

const clearRecaptchaContainer = (containerId) => {
	// Clearing the RecaptchaVerifier
	const container = document.getElementById(containerId);
	if (container) container.innerHTML = '';
};

export const sendPhoneNumberOTP = async (phoneNumber, callback) => {
	try {
		clearRecaptchaContainer('recaptcha-container');
		const recaptchaVerifier = new firebase.auth.RecaptchaVerifier('recaptcha-container', {
			size: 'small',
		});
		const authProvider = new firebase.auth.PhoneAuthProvider();
		const otpVerificationId = await authProvider.verifyPhoneNumber(
			{
				phoneNumber,
			},
			recaptchaVerifier
		);
		callback(null, otpVerificationId);
	} catch (err) {
		console.log(err);
		callback('Error sending phone number OTP: ' + err.message);
	}
};

export const verifyPhoneNumberOTP = (otp, verificationId, callback) => {
	try {
		const credential = firebase.auth.PhoneAuthProvider.credential(verificationId, otp);
		if (credential) {
			firebasePrimaryApp
				.auth()
				.signInWithCredential(credential)
				.then(() => callback(null, credential))
				.catch(callback);
		}
	} catch (err) {
		console.log('Error verifying phone number OTP', err.message);
		callback(err, null);
	}
};

