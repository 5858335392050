import constants from '../constants';
import db, { firestore } from '../../firebase/database';
import { parseJsonSafe } from 'sl-web-base/base/utils';
import callCloudFunction from '../../firebase/utils/callCloudFunction';
import { deleteCachedData } from '../helpers/cacheHelper';

export const getOAndMsToRate = async (phoneNumber, startAfter, callback) => {
	try {
		let OAndMRef = db
			.collection(constants.OPERATIONSANDMAINTENANCECOLLECTION)
			.where('siteManagers', 'array-contains', phoneNumber);

		OAndMRef = OAndMRef.orderBy('updatedAt', 'desc').limit(10);

		if (startAfter) OAndMRef = OAndMRef.startAfter(startAfter);

		return callback(null, (await OAndMRef.get()).docs);
	} catch (err) {
		console.log(err);
		return callback(err.message, null);
	}
};

export const getOAndMData = async (OAndMId) => {
	try {
		return (
			await db.collection(constants.OPERATIONSANDMAINTENANCECOLLECTION).doc(OAndMId).get()
		).data();
	} catch (err) {
		console.log(err);
		return null;
	}
};

export const getOAndMRating = async (instanceId) => {
	try {
		return (
			await db.collection(constants.OPERATIONSANDMAINTENANCERATINGS).doc(instanceId).get()
		).data();
	} catch (err) {
		console.log(err);
		return null;
	}
};

export const getOAndMInstance = async (instanceId) => {
	try {
		return (
			await db.collection(constants.OPERATIONSANDMAINTENANCEINSTANCES).doc(instanceId).get()
		).data();
	} catch (err) {
		console.log(err);
		return null;
	}
};

export const sendOAndMRating = async (onmInstanceId, ratingInfo, callback) => {
	try {
		const response = await callCloudFunction('rateOAndM', {
			onmInstanceId,
			ratingInfo: JSON.stringify(ratingInfo),
		});

		if (ratingInfo.templateId && typeof ratingInfo.templateId === 'string')
			deleteCachedData(ratingInfo.templateId);

		callback(null, response.submissionId);
	} catch (err) {
		console.log('ERROR SENDING RATING', err);
		return callback(err.message, null);
	}
};

export const getRatingInfo = async (onmId, onmInstanceId, callback) => {
	try {
		const response = await callCloudFunction('getOAndMRatingInfo', {
			onmId,
			onmInstanceId,
		});

		const onmInfo = parseJsonSafe(response?.onmInfo ?? '{}');
		const onmInstanceInfo = parseJsonSafe(response?.onmInstanceInfo ?? '{}');

		callback(null, {
			onmInfo: { ...onmInfo },
			onmInstanceInfo: {
				...onmInstanceInfo,
				ratedAt: onmInstanceInfo.ratedAt?._seconds
					? new Date(onmInstanceInfo.ratedAt._seconds * 1000)
					: onmInstanceInfo.ratedAt,
			},
		});
	} catch (err) {
		console.log('Error getting rating info', err);
		return callback(err.message, null);
	}
};
