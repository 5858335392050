export const copyText = (domNode = null, callback = () => {}) => {
	if (domNode && callback) {
		let target = domNode;
		let range, select;
		if (document.createRange) {
			range = document.createRange();
			range.selectNode(target);
			select = window.getSelection();
			select.removeAllRanges();
			select.addRange(range);
			document.execCommand('copy');
			select.removeAllRanges();
		} else {
			range = document.body.createTextRange();
			range.moveToElementText(target);
			range.select();
			document.execCommand('copy');
		}

		callback(null);
	}
};

export const parseJsonSafe = (str) => {
	if (typeof str == 'string') {
		try {
			return JSON.parse(str);
		} catch (err) {
			return {};
		}
	} else return str;
};

export const getMonthAndDayString = (date) => {
	var months = [
		'January',
		'February',
		'March',
		'April',
		'May',
		'June',
		'July',
		'August',
		'September',
		'October',
		'November',
		'December',
	];

	return date.getDate() + ' ' + months[date.getMonth()];
};

export const formattedDate = (timestamp = window.now ? window.now() : new Date()) => {
	timestamp = new Date(timestamp);

	let year = timestamp.getFullYear();
	let month = timestamp.getMonth() + 1;
	let date = timestamp.getDate();

	if (month < 10) {
		month = '0' + month;
	}
	if (date < 10) {
		date = '0' + date;
	}

	return `${year}-${month}-${date}`;
};

export const diffDays = (date1, date2, ceil = true) => {
	date1 = new Date(date1);
	date2 = new Date(date2);

	let diff = Math.abs(date2.getTime() - date1.getTime()) / (1000 * 3600 * 24);

	if (ceil) return Math.ceil(diff);
	else return diff;
};

export const intersection = (array1, array2) => array1.filter((value) => array2.includes(value));

export const notIntersection = (array1, array2) =>
	array1.filter((value) => !array2.includes(value));
