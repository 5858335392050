import { openDB } from 'idb';

// Caches the given key and value
export const cacheData = async (key = '', value) => {
	try {
		const db = await openDB('cache_db', 1, {
			upgrade: (db) => {
				if (!db.objectStoreNames.contains('general_cache')) {
					db.createObjectStore('general_cache');
				}
			},
		});

		await db
			.transaction('general_cache', 'readwrite')
			.objectStore('general_cache')
			.put(value, key);
		return true;
	} catch (err) {
		console.log('Error caching data', err);
		return false;
	}
};

// Reads huge payload which was written using cacheData()
export const readCachedData = async (key = '') => {
	try {
		const db = await openDB('cache_db', 1, {
			upgrade: (db) => {
				if (!db.objectStoreNames.contains('general_cache')) {
					db.createObjectStore('general_cache');
				}
			},
		});

		if (key) return await db.transaction('general_cache').store.get(key);
		let keys = await db.transaction('general_cache').store.getAllKeys();
		let values = await db.transaction('general_cache').store.getAll();
		return keys.reduce((acc, curr, index) => ({ ...acc, [curr]: values[index] }), {});
	} catch (err) {
		console.log('Error caching data', err);
		return null;
	}
};

export const deleteCachedData = async (key = '') => {
	try {
		const db = await openDB('cache_db', 1, {
			upgrade: (db) => {
				if (!db.objectStoreNames.contains('general_cache')) {
					db.createObjectStore('general_cache');
				}
			},
		});

		const transaction = db.transaction('general_cache', 'readwrite');
		const store = transaction.objectStore('general_cache');
		await store.delete(key);
		await transaction.done;
	} catch (err) {
		console.log('Error deleting cached data', err);
	}
};
