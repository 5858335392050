const handleCallableFunctionsResponse = (response) => {
	if (!response.data) throw new Error('Something went wrong. Please try again later.');
	if (response.data.httpErrorCode && response.data.details) {
		const error = {
			message:
				response.data.details.errorMessage || response.data.httpErrorCode.canonicalName,
			statusCode: response.data.details.statusCode || response.data.httpErrorCode.status,
			code: response.data.code,
		};
		throw error;
	}
	return response.data;
};

export default handleCallableFunctionsResponse;
