import React, { useEffect } from 'react';
import { Switch, Route } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import Notifications from 'react-notify-toast';

// Global Store actions.
import * as actionCreators from './app/store/actionCreators';

import ProtectedRoute from './app/components/reusables/ProtectedRoute';

// Page components
import HomePage from './app/components/container/HomePage';
import Dashboard from './app/components/container/Dashboard';
import RatingPage from './app/components/container/RatingPage';

// Firebase Services
import auth from './firebase/authentication';
import analytics from './firebase/analytics';
import Loader from './app/components/reusables/Loader';
import ThemeProvider from 'app/components/reusables/ThemeProvider';

const App = () => {
	const showLoader = useSelector((state) => state.showLoader);

	const dispatch = useDispatch();

	const logoutUser = async () => {
		dispatch(actionCreators.logoutUser());
		await auth.signOut();
	};

	const authChangeListener = async (user) => {
		// Get general configuration.
		window.now = () => new Date();
		if (user) {
			let displayName = user.displayName,
				email = user.email,
				emailVerified = user.emailVerified,
				photoURL = user.photoURL,
				isAnonymous = user.isAnonymous,
				phoneNumber = user.phoneNumber,
				uid = user.uid,
				providerData = user.providerData,
				lastSignInTime = user.metadata.lastSignInTime;

			let userToSave = {
				displayName,
				email,
				emailVerified,
				photoURL,
				isAnonymous,
				phoneNumber,
				uid,
				id: uid,
				role: 'rater',
				userType: 'customer',
				providerData: JSON.parse(JSON.stringify(providerData)),
				lastSignInTime,
			};

			// Log the cleaner user in.
			dispatch(actionCreators.loginUser(userToSave));

			// Log user properites to analytics.
			let userProperties = {
				email,
				emailVerified,
				displayName,
				userType: 'customer',
				app: 'oandm-rater-app',
				phoneNumber,
			};
			analytics.setUserProperties(userProperties);
		} else logoutUser();
	};

	useEffect(() => {
		auth.onAuthStateChanged(authChangeListener);
	}, []);

	// Actual app.
	return (
		<React.Fragment>
			<ThemeProvider>
				{/* Global Toast for error messages */}
				<Notifications />

				{showLoader ? <Loader text={showLoader} /> : ''}

				<Switch>
					<Route path={'/'} exact>
						<HomePage logoutUser={logoutUser} />
					</Route>
					<ProtectedRoute
						path={'/dashboard'}
						logoutUser={logoutUser}
						component={(prps) => <Dashboard {...{ ...prps, logoutUser }} />}
					/>
					<ProtectedRoute
						path={'/public/rate/:OAndMId/:OAndMInstanceId'}
						component={(prps) => <RatingPage {...prps} isPublic />}
					/>
					<ProtectedRoute
						path={'/rate/:OAndMId/:OAndMInstanceId'}
						component={RatingPage}
					/>
				</Switch>
			</ThemeProvider>
		</React.Fragment>
	);
};
export default App;
