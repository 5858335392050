import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Redirect } from 'react-router';
import toasts from 'app/constants/toastConstants';
import HomePageUI from '../presentational/HomePageUI';
import { sendPhoneNumberOTP, verifyPhoneNumberOTP } from 'firebase/authentication';

const HomePage = (props) => {
	const state = useSelector((state) => state);

	let redirectTo = window?.location?.search || '/dashboard';

	const [redirectToDashboard, setredirectToDashboard] = useState(
		state.isAuthenticated && state.user && state.user.phoneNumber
	);
	const [phone, setphone] = useState('');
	useEffect(() => {
		if (state.isAuthenticated && state.user) return setredirectToDashboard(true);
	}, [state.isAuthenticated, state.user]);

	const [phoneNumber, setPhoneNumber] = useState('');
	const [code, setCode] = useState('');
	const [codeSent, setCodeSent] = useState(false);
	const [showRecaptcha, setShowRecaptcha] = useState(false);
	const [verificationId, setVerificationId] = useState('');
	const [isVerifieď, setisVerifieď] = useState(false);
	const [modifyPhone, setmodifyPhone] = useState(true);
	const [showModifyPhone, setshowModifyPhone] = useState(false);
	const handleSendCode = async () => {
		if (phoneNumber?.length !== 10) return toasts.generateError('Invalid phone number.');
		setCodeSent(true);
		setShowRecaptcha(true);
		setmodifyPhone(false);
		sendPhoneNumberOTP(`+91${phoneNumber}`, (err, otpVerificationId) => {
			if (err) {
				setShowRecaptcha(false);
				setmodifyPhone(true);
				setCodeSent(false);
				return toasts.generateError(err);
			}
			setshowModifyPhone(true);
			toasts.generateSuccess(`OTP sent to +91${phoneNumber}`);
			setVerificationId(otpVerificationId);
			setShowRecaptcha(false);
		});
	};

	const handleModifyPhoneNumber = () => {
		setCode('');
		setmodifyPhone((prev) => !prev);
	};
	const handleVerifyCode = async () => {
		verifyPhoneNumberOTP(code, verificationId, (err, responseData) => {
			if (err) {
				toasts.generateError(err.message || 'Invalid OTP');
			} else {
				setisVerifieď(true);
				toasts.generateSuccess('Logged in successfully.');
				setredirectToDashboard(true);
			}
		});
	};

	return redirectToDashboard && state.user ? (
		<Redirect to={decodeURIComponent(redirectTo.split('=').pop())} />
	) : (
		<HomePageUI
			handleSendCode={handleSendCode}
			phoneNumber={phoneNumber}
			setPhoneNumber={setPhoneNumber}
			setCodeSent={setCodeSent}
			codeSent={codeSent}
			phone={phone}
			showRecaptcha={showRecaptcha}
			setphone={setphone}
			code={code}
			modifyPhone={modifyPhone}
			isVerified={isVerifieď}
			setCode={setCode}
			handleModifyPhoneNumber={handleModifyPhoneNumber}
			handleVerifyCode={handleVerifyCode}
			showModifyPhone={showModifyPhone}
			setshowModifyPhone={setshowModifyPhone}
		/>
	);
};

export default HomePage;
