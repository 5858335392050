import React from 'react';
import { motion, AnimatePresence } from 'framer-motion';

// Icon
import ClearIcon from '@mui/icons-material/Clear';

// Buttons
import IconButton from '@mui/material/IconButton';

const NewModal = (props) => {
	return (
        (<AnimatePresence>
            <motion.div
				className={`newmodal-container ${props.containerClass || ''}`}
				initial={{ opacity: 0 }}
				animate={{ opacity: 1 }}
				exit={{ opacity: 0 }}
			>
				<motion.div
					className={`newmodal ${props.className || ''}`}
					initial={{ scale: 0 }}
					transition={{ duration: 0.45 }}
					animate={{ scale: 1 }}
					exit={{ scale: 0 }}
				>
					<div className={'newmodal-top row'}>
						<div className={'col-11 newmodal-top-title'}>{props.heading}</div>
						{props.toggler && (
							<div className={'col-1 alignright'}>
								<IconButton onClick={props.toggler} className={'closeicon'} size="large">
									<ClearIcon />
								</IconButton>
							</div>
						)}
					</div>
					<div className={`newmodal-content ${props.contentClassName || ''}`}>
						{props.children || ''}
					</div>
				</motion.div>
			</motion.div>
        </AnimatePresence>)
    );
};

export default NewModal;
