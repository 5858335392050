import React from 'react';

import { List } from '@mui/material';

import NewModal from '../../reusables/Modal';
import OAndMTable from './OAndMTable';

const ActiveOAndMUI = (props) => {
	return (
		<NewModal heading={'O&M'} toggler={props.toggler}>
			{props.OAndM?.OAndMPerformedOn?.length ? (
				<OAndMTable
					cyclesSchedule={props.OAndM?.cyclesSchedule}
					scheduleType={props.OAndM?.scheduleType ?? 'automatic'}
					OAndMDays={props.OAndMDays}
					OAndMPerformedOn={props.OAndM?.OAndMPerformedOn || []}
					toggleActiveOAndMInstance={props.toggleActiveOAndMInstance}
					OAndMInProgressOn={props.OAndM?.OAndMInProgressOn || []}
					OAndMRatedOn={props.OAndM?.OAndMRatedOn || []}
					OAndM={props.OAndM}
				/>
			) : (
				<div className={'nonefound'}>
					<List />
					<br />
					No O&Ms completed so far.
				</div>
			)}
		</NewModal>
	);
};

export default ActiveOAndMUI;
