import React from 'react';

import { Button } from '@mui/material';
import { ArrowDownward, List } from '@mui/icons-material';

import OAndMCard from './OAndMCard';

const OAndMUI = (props) => {
	return (
		<div className={'dashboard-oandm'}>
			{props.OAndMs?.length ? (
				<>
					<div className={'row pb-3'}>
						{props.OAndMs?.map((OAndM, index) => (
							<div className={'col-sm-4 p-2'} key={OAndM.id}>
								<OAndMCard
									OAndM={OAndM}
									toggleActiveOAndM={() => props.toggleActiveOAndM(index)}
								/>
							</div>
						))}
					</div>
					{props.loadMore && (
						<>
							<br />
							<div className={'text-center'}>
								<Button
									startIcon={<ArrowDownward />}
									variant={'contained'}
									color={'primary'}
									onClick={props.fetchOAndMs}
								>
									Load More
								</Button>
							</div>
						</>
					)}
					<br />
				</>
			) : (
				<div className="nonefound">
					<List />
					<br />
					No Operations And Maintenance records to rate found.
				</div>
			)}
		</div>
	);
};

export default OAndMUI;
