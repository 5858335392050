import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import auth from '../../../../firebase/authentication';

import { getOAndMsToRate } from '../../../API';
import toasts from '../../../constants/toastConstants';
import useLoader from '../../../helpers/useLoader';

import OAndMUI from '../../presentational/DashboardUI/OAndMUI';
import ActiveOAndM from './ActiveOAndM';

const OAndM = () => {
	const state = useSelector((state) => state);

	const loader = useLoader();

	const [OAndMs, setOAndMs] = useState([]);
	const [startAfter, setstartAfter] = useState(null);
	const [loadMore, setloadMore] = useState(false);

	const [activeOAndMIndex, setactiveOAndMIndex] = useState(undefined);
	const [activeOAndM, setactiveOAndM] = useState(null);

	const fetchOAndMs = async () => {
		loader('Loading O&Ms');
		getOAndMsToRate(auth.currentUser.phoneNumber, startAfter, (err, docs) => {
			loader();
			if (err) return toasts.generateError(err);
			if (docs.length < 10) setloadMore(false);

			if (docs.length) {
				setstartAfter(docs[docs.length - 1]);
				setOAndMs((oAndMs) => [
					...(oAndMs || []),
					...docs.map((doc) => ({
						...doc.data(),
						id: doc.id,
						developerName:
							state?.associatedDevelopers?.[doc.data().developer]?.developerName,
					})),
				]);
			}
		});
	};

	useEffect(() => {
		if (auth.currentUser) fetchOAndMs();
	}, [auth.currentUser]);

	const toggleActiveOAndM = (OAndMindex = undefined) => {
		if (activeOAndMIndex === OAndMindex || OAndMindex === undefined) {
			setactiveOAndM(null);
			setactiveOAndMIndex(undefined);
		} else {
			setactiveOAndMIndex(OAndMindex);
			setactiveOAndM(OAndMs[OAndMindex]);
		}
	};

	return (
		<>
			{activeOAndM !== null && activeOAndMIndex !== undefined ? (
				<ActiveOAndM
					OAndM={OAndMs[activeOAndMIndex]}
					toggler={() => toggleActiveOAndM(undefined)}
				/>
			) : (
				''
			)}
			<OAndMUI
				OAndMs={OAndMs}
				fetchOAndMs={fetchOAndMs}
				loadMore={loadMore}
				toggleActiveOAndM={toggleActiveOAndM}
			/>
		</>
	);
};

export default OAndM;
