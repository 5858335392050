import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Redirect } from 'react-router-dom';
import { getFormTemplateInfo } from 'sl-web-base/base/api/FormBuilder';
import { parseJsonSafe } from 'sl-web-base/base/utils/index';

import toasts from '../../constants/toastConstants';
import { getRatingInfo, sendOAndMRating } from '../../API';
import RatingPageUI from '../presentational/RatingPageUI';
import useLoader from '../../helpers/useLoader';
import auth from '../../../firebase/authentication';

const RatingPage = (props) => {
	const loader = useLoader();

	// State Variables
	const [redirectToHome, setredirectToHome] = useState(false);
	const [OAndMId, setOAndMId] = useState('');
	const [OAndMInstanceId, setOAndMInstanceId] = useState('');
	// Data
	const [OAndMData, setOAndMData] = useState(null);
	const [OAndMInstanceData, setOAndMInstanceData] = useState(null);
	// Rating info
	const [ratingInfo, setratingInfo] = useState();
	const [ratingFormData, setRatingFormData] = useState();
	const [rated, setRated] = useState(
		OAndMData?.OAndMRatedOn?.includes(OAndMInstanceId?.split?.('-')?.slice?.(1)?.join?.('-')) ||
			false
	);
	const [showPreview, setShowPreview] = useState(false);

	// Refs
	const setInitial = useRef(null);

	const fetchData = useCallback(
		async (OAndMIdParam, OAndMInstanceIdParam) => {
			loader('Loading Form');

			getRatingInfo(OAndMIdParam, OAndMInstanceIdParam, async (err, data) => {
				loader();

				if (err) toasts.generateError(`Error rating. ${err}`);

				const OAndMDataFetched = data?.onmInfo;
				const OAndMInstanceDataFetched = data?.onmInstanceInfo;
				// Checking OnM Data
				if (!OAndMDataFetched || !OAndMInstanceDataFetched) return setredirectToHome(true);
				setOAndMData(OAndMDataFetched);
				setOAndMInstanceData(OAndMInstanceDataFetched);

				// If it's public submission then let the user rate
				// otherwise check if sitemanager is assigned this onm or not
				if (!props.isPublic) {
					if (!OAndMDataFetched.siteManagers?.includes?.(auth.currentUser?.phoneNumber)) {
						// Not this user's onm, goto home
						return setredirectToHome(true);
					}
				}

				let OAndMAlreadyRated = false;

				// Checking if instance already rated
				if (
					OAndMDataFetched?.OAndMRatedOn?.includes(
						OAndMInstanceIdParam?.split?.('-')?.slice?.(1)?.join?.('-')
					) ||
					false
				) {
					// This instance has already been rated.
					setRated(true);
					OAndMAlreadyRated = true;
				}

				if (
					OAndMAlreadyRated ||
					(OAndMInstanceDataFetched.ratedBySiteManager &&
						OAndMInstanceDataFetched.ratingInfo)
				) {
					setRated(true);

					if (
						Object.keys(parseJsonSafe(OAndMInstanceDataFetched.ratingInfo ?? '')).length
					) {
						setratingInfo(parseJsonSafe(OAndMInstanceDataFetched.ratingInfo ?? '{}'));
						setRatingFormData(
							parseJsonSafe(
								parseJsonSafe(OAndMInstanceDataFetched.ratingInfo ?? '{}')
									?.templateJson
							)
						);
					} else {
						setratingInfo(OAndMInstanceDataFetched.ratingInfo);
					}
				} else {
					// Loading OnM Rating Form Data
					loader('Loading Form');
					const formData = await getFormTemplateInfo(OAndMDataFetched.customerRatingForm);
					loader();
					if (!formData) return setredirectToHome(true);
					setRatingFormData({
						...formData,
						templateJson: parseJsonSafe(formData.templateJson),
					});
				}
			});
		},
		[props.isPublic, loader]
	);

	useEffect(() => {
		if (
			props.match &&
			props.match.params &&
			props.match.params.OAndMId &&
			props.match.params.OAndMInstanceId
		) {
			if (!setInitial.current) {
				setOAndMId(props.match.params.OAndMId);
				setOAndMInstanceId(props.match.params.OAndMInstanceId);

				setInitial.current = true;

				fetchData(props.match.params.OAndMId, props.match.params.OAndMInstanceId);
			}
		} else {
			setredirectToHome(true);
		}
	}, [props.match, fetchData]);

	const submitFeedback = useCallback(
		async (data) => {
			if (!data) return toasts.generateError('Rating not provided.');

			loader('Rating O&M');

			sendOAndMRating(
				OAndMInstanceId,
				{ ...data, templateId: OAndMData.customerRatingForm },
				(err, submissionId) => {
					loader();
					if (err) return toasts.generateError(err);

					setratingInfo(submissionId);
					setRated(true);
					toasts.generateSuccess('Successfully rated O&M.');
				}
			);
		},
		[OAndMInstanceId, loader, OAndMData]
	);

	return redirectToHome ? (
		<Redirect to={'/dashboard'} />
	) : (
		<RatingPageUI
			rated={rated}
			OAndMInstanceData={OAndMInstanceData}
			OAndMId={OAndMId}
			OAndMInstanceId={OAndMInstanceId}
			ratingInfo={ratingInfo}
			submitFeedback={submitFeedback}
			ratingFormData={ratingFormData}
			loader={loader}
			showPreview={showPreview}
			setShowPreview={setShowPreview}
		/>
	);
};

export default RatingPage;
