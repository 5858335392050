import React, { useMemo, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { Route, Redirect } from 'react-router-dom';

const ProtectedRoute = ({ component: Component, ...rest }) => {
	const user = useSelector((state) => state.user);

	const render = useCallback(
		(props) => {
			let pathToRedirectTo = rest?.computedMatch?.url ?? '/dashboard';
			let queryParams = rest?.location?.search ?? '';

			if (queryParams && queryParams.startsWith('?'))
				pathToRedirectTo += encodeURIComponent(queryParams);

			return user ? (
				<Component {...props} />
			) : (
				<Redirect to={`/?redirect=${pathToRedirectTo}`} />
			);
		},
		[user]
	);

	return <Route {...rest} render={render} />;
};

export default ProtectedRoute;
