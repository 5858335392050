import React from 'react';
import { AccountCircle, ExitToApp } from '@mui/icons-material';
import { Box, Button, IconButton, Popper } from '@mui/material';
import { useState } from 'react';

const DashboardUI = (props) => {
	const [profileAnchor, setProfileAnchor] = useState();

	return (
        (<div className={'dashboard'}>
            <div className={'dashboard-navbar'}>
				<div className={'fixedcontainer nopadding dashboard-navbar-tabsContainer'}>
					{props.tabs && props.tabs.length
						? props.tabs.map((tab, index) =>
								!tab.hide ? (
									<a
										href={'#'}
										key={index}
										onClick={(e) => {
											e.preventDefault();
											props.switchTab(index);
										}}
										className={`tabswitcher ${
											props.activeTab === index ? 'active' : ''
										}`}
									>
										{tab.tabIcon}&nbsp;{tab.tabName}
									</a>
								) : (
									''
								)
						  )
						: ''}

					{/* Logout btn */}
					<div className="dashboard-navbar-tabsContainer-profile">
						<IconButton
                            onClick={(ev) => setProfileAnchor((a) => (a ? null : ev.currentTarget))}
                            size="large">
							<AccountCircle />
						</IconButton>
						<Popper open={profileAnchor} anchorEl={profileAnchor}>
							<Box className="dashboard-navbar-tabsContainer-profile-box">
								<b>{props.user?.displayName ?? 'User'}</b>
								<br />
								{props.user?.phoneNumber}
								<br />
								<br />
								<Button
									color={'secondary'}
									onClick={props.logoutUser}
									variant={'contained'}
									endIcon={<ExitToApp />}
								>
									Sign Out
								</Button>
							</Box>
						</Popper>
					</div>
				</div>
			</div>
            {/* Render components here. */}
            {props.tabs[props.activeTab] ? (
				<div
					className={
						!props.tabs[props.activeTab].isFullWidth
							? 'fixedcontainer'
							: 'standardpadding'
					}
				>
					<div className={'dashboard-top dashboard-top mb-2 align-items-center row'}>
						<div className={`dashboard-top-heading col-4`}>
							{props.tabs[props.activeTab].tabName}
						</div>
						<div className={'col-8 dashboard-top-options alignright'}>
							{props.topRightComponent ? props.topRightComponent : ''}
						</div>
					</div>
					{props.tabs[props.activeTab].component}
				</div>
			) : (
				''
			)}
        </div>)
    );
};

export default DashboardUI;
