import React, { useMemo } from 'react';

import Typography from '@mui/material/Typography';
import { FormModal } from 'sl-web-base';
import { ThumbUp } from '@mui/icons-material';
import { message } from 'antd';

const RatingPageUI = ({
	OAndMInstanceData,
	rated,
	ratingFormData,
	OAndMId,
	OAndMInstanceId,
	ratingInfo,
	submitFeedback,
}) => {
	const ratingDone = useMemo(
		() => OAndMInstanceData?.ratedAt || rated,
		[OAndMInstanceData, rated]
	);
	const showRatingForm = useMemo(
		() => !ratingDone && ratingFormData,
		[ratingDone, ratingFormData]
	);
	const showFormPreview = useMemo(() => ratingDone, [ratingDone]); // Show Preview post submission

	const formContextData = useMemo(
		() => ({
			formType: 'customerReview',
			onmId: OAndMId,
			instanceId: OAndMInstanceId,
		}),
		[OAndMId, OAndMInstanceId]
	);

	return (
		<div className={'ratingpage fixedcontainer text-center p-3'}>
			<Typography component="h4" variant="h4">
				Rate O&M
			</Typography>

			{ratingDone ? (
				<React.Fragment>
					<ThumbUp style={{ fontSize: '5em' }} />
					<br />
					<br />
					<p>Rated At: {ratingDone?.toDateString?.() || new Date().toDateString()}</p>
				</React.Fragment>
			) : null}

			<br />

			{showRatingForm || showFormPreview ? (
				<div className="text-left">
					<FormModal
						entityId={OAndMInstanceId || OAndMId}
						formData={ratingFormData}
						formValues={ratingInfo?.values}
						submissionId={typeof ratingInfo === 'string' ? ratingInfo : null}
						handleSubmit={(data) => submitFeedback(data)}
						readOnly={ratingInfo}
						showLoader={(val) => {
							if (val) {
								const toast = message.loading(
									typeof val === 'string' ? val : 'Loading',
									0
								);
								setTimeout(toast, 1200);
							}
						}}
						contextData={formContextData}
					/>
				</div>
			) : null}

			{/* Rating form not found */}
			{!showRatingForm && !ratingDone ? <p>Rating Form Not found</p> : null}
		</div>
	);
};

export default RatingPageUI;
