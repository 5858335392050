import functions from '../functions';
import handleCallableFunctionsResponse from './callableFunctionsResponse';

const callCloudFunction = async (cloudFunctionName: string, data?: any) => {
	const cloudFunction = functions.httpsCallable(cloudFunctionName);
	const response = handleCallableFunctionsResponse(await cloudFunction(data));
	return response;
};

export default callCloudFunction;
