const appConstants = {
	APPNAME: 'Solar Ladder External O&M Cleaning Dashboard',

	// Database Collections
	DEVELOPERSCOLLECTION: 'developers',
	EXTERNALOANDMCLEANERS: 'externaloandmcleaners',
	EXTERNALOANDMCLEANERUSERS: 'externaloandmcleanerusers',
	OPERATIONSANDMAINTENANCECOLLECTION: 'operationsandmaintenance',
	OPERATIONSANDMAINTENANCEINSTANCES: 'operationsandmaintenanceinstances',
	OPERATIONSANDMAINTENANCERATINGS: 'operationsandmaintenanceratings',
	CUSTOMFORM_PUBLICSUBMISSIONS: 'customformspublicsubmissions',
};

export default appConstants;
