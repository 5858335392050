import React, { useMemo } from 'react';

import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

import { Business, Loop, Visibility, Timer, Check, Star } from '@mui/icons-material';
import { generateMapImageLink } from '../../../helpers/mapHelpers';
import { getDaysForOAndM, getNextOAndMDate } from '../../../helpers/OAndMHelpers';
import { diffDays, formattedDate } from '../../../helpers';

const OAndMCard = (props) => {
	const nextOAndMDate = useMemo(
		() =>
			getNextOAndMDate(
				getDaysForOAndM(
					props.OAndM?.duration,
					props.OAndM?.durationUnit,
					formattedDate(props.OAndM?.startDate?.toDate?.()) || props.OAndM?.startDate,
					props.OAndM?.frequency,
					props.OAndM?.frequencyUnit
				),
				props.OAndM?.OAndMPerformedOn || [],
				props.OAndM?.OAndMInProgressOn || [],
				props.OAndM?.changedDates || {}
			),
		[props.OAndM]
	);

	const getFrequencyText = () => {
		if (!props.OAndM.scheduleType || props.OAndM.scheduleType === 'scheduling')
			return `Every ${props.OAndM?.frequency} ${props.OAndM?.frequencyUnit}(s)`;

		return `Scheduling: ${(props.OAndM.scheduleType + '').toUpperCase()}`;
	};

	let nextDue = nextOAndMDate ? Math.ceil(diffDays(new Date(), nextOAndMDate)) : null;

	return props.OAndM ? (
		<Card variant={'outlined'} className={'oandmcard'} style={{ cursor: 'pointer' }}>
			<CardMedia
				component="img"
				alt={'O&M Location'}
				height="150"
				image={
					(props.OAndM?.location?.latitude && props.OAndM?.location?.longitude) ||
					(props.OAndM?.location?.lat && props.OAndM?.location?.lng)
						? generateMapImageLink(
								`${props.OAndM.location.latitude || props.OAndM.location.lat},${
									props.OAndM.location.longitude || props.OAndM.location.lng
								}`
						  )
						: (props.OAndM?.mapInfo?.latitude && props.OAndM?.mapInfo?.longitude) ||
						  (props.OAndM?.mapInfo?.lat && props.OAndM?.mapInfo?.lng)
						? generateMapImageLink(
								`${props.OAndM.mapInfo.latitude || props.OAndM.mapInfo.lat},${
									props.OAndM.mapInfo.longitude || props.OAndM.mapInfo.lng
								}`
						  )
						: '/images/noimageplaceholder.jpg'
				}
				title={'O&M Location'}
			/>
			<CardContent>
				<Typography variant="h6" component="h2" className={'oandmcard-heading'}>
					{props.OAndM?.onmUniqueId ?? props.OAndM?.projectUniqueId} -{' '}
					{props.OAndM?.companyName || props.OAndM?.customerName || ''}
				</Typography>
				<Typography color="textSecondary" gutterBottom>
					<Loop /> {getFrequencyText()}
					<br />
					<Check />
					{props.OAndM?.OAndMPerformedOn?.length || 0} O{'&'}Ms Completed
				</Typography>
				<br />
				<Typography
					color="textSecondary"
					style={{ fontSize: '0.875rem', color: nextDue < 0 ? 'var(--danger)' : '' }}
					gutterBottom
				>
					<Star fontSize="small" /> {props.OAndM?.OAndMRatedOn?.length || 0} O&Ms Rated
				</Typography>
			</CardContent>
			<CardActions style={{ paddingTop: 0, marginTop: 0 }}>
				<Button
					color="primary"
					startIcon={<Visibility />}
					onClick={props.toggleActiveOAndM}
				>
					View Details
				</Button>
			</CardActions>
		</Card>
	) : (
		''
	);
};

export default OAndMCard;
