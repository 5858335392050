import React from 'react';
import { BsPhone } from 'react-icons/bs';
import { BiMessageAltDetail } from 'react-icons/bi';

import LoginUI from 'sl-web-base/base/reusables/LoginUI';

import '../../../static/partials/pages/home.scss';
import useDebounce from 'app/helpers/useDebounce';
function HomePageUI(props) {
	const {
		codeSent,
		code,
		setCode,
		phoneNumber,
		setPhoneNumber,
		handleSendCode,
		handleVerifyCode,
		showRecaptcha,
		isVerified,
		modifyPhone,
		showModifyPhone,
		handleModifyPhoneNumber,
	} = props;

	const inputs = modifyPhone
		? [
				{
					icon: <BsPhone />,
					type: 'number',
					value: phoneNumber,
					maxLength: 10,
					onChange: (e) => {
						const phoneNumber = e.target.value.slice(0, 10); // Get the first 10 characters of the input value
						setPhoneNumber(phoneNumber);
					},
					placeholder: 'Enter Phone Number',
					bottomLeftText: showModifyPhone ? 'Already have OTP' : '',
					bottomLeftAction: handleModifyPhoneNumber,
					isHidden: showRecaptcha || isVerified,
					adornment: <div style={{ fontSize: '14px' }}>+91</div>,
				},
		  ]
		: [
				{
					icon: <BiMessageAltDetail />,
					type: 'number',
					maxLength: 6,
					value: code,
					onChange: (e) => {
						const otp = e.target.value.slice(0, 6);
						setCode(otp);
					},
					placeholder: '6-digit code',
					bottomLeftText: 'Resend OTP',
					bottomRightText: 'Modify phone Number',
					bottomRightAction: () => handleModifyPhoneNumber(),
					bottomLeftAction: () => handleSendCode(),
					isHidden: showRecaptcha || isVerified,
				},
		  ];

	const debouncedSendVerificationRequest = useDebounce(
		() => {
			if (isVerified) {
				return;
			}
			if (modifyPhone) {
				handleSendCode();
			} else {
				handleVerifyCode();
			}
		},
		[phoneNumber, code, isVerified, modifyPhone],
		600
	);
	return (
		<LoginUI
			logo={'/logo.png'}
			background={'/images/loginpageasset.svg'}
			headerText={isVerified ? 'Logged In' : 'Welcome back!'}
			onFormSubmit={(event) => {
				event.preventDefault();
				debouncedSendVerificationRequest();
			}}
			disabled={showRecaptcha}
			headerHelpertext={
				<p style={{ fontSize: '12px', marginBottom: '3px', textAlign: 'center' }}>
					{!modifyPhone &&
						!isVerified &&
						`Please verify your mobile number (+91${phoneNumber}) to approve your account.`}
				</p>
			}
			submitText={modifyPhone ? 'Send OTP' : 'Continue'}
			isSubmitHidden={showRecaptcha || isVerified}
			themeColor="#03989E"
			pageSwitch={handleModifyPhoneNumber}
			inputs={inputs}
			showRecaptcha={showRecaptcha}
			recaptchaComponent={<div id="recaptcha-container" style={{ display: 'block' }}></div>}
		/>
	);
}

export default HomePageUI;
