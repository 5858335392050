import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/lib/integration/react'; // Redux-persist.
import initializeWebBaseEnvironment from 'sl-web-base/base/utils/initializeEnvironment';

import './static/main.scss';

import { store, persistor } from './app/store';
import App from './App';

// Production requirements.
import { disableReactDevTools } from './prod';

// Remove react dev tools for production build to avoid state manipulation by user
if (process.env.REACT_APP_USE_ENV !== 'development') disableReactDevTools();

// Initializing web-base
initializeWebBaseEnvironment(process.env);

ReactDOM.render(
	<Provider store={store}>
		<PersistGate persistor={persistor}>
			<Router>
				<App />
			</Router>
		</PersistGate>
	</Provider>,
	document.getElementById('root')
);
