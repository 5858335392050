// Custom Loader Component

import React from "react";
import LoopIcon from "@mui/icons-material/Loop";

const Loader = props => {
	return (
		<div className={"loader-container"}>
			<div className={"loader"}>
				<LoopIcon />
				<div className={"loader-text"}>
					{props.text ? props.text : "Loading"}
				</div>
			</div>
		</div>
	);
};

export default Loader;
