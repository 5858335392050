import { get } from 'axios';

export const getAddressFromPoints = async (location) => {
	try {
		let url = `https://maps.googleapis.com/maps/api/geocode/json?`;
		let params = new URLSearchParams();
		params.append('latlng', `${location.lat},${location.lng}`);
		params.append(
			'key',
			process.env.REACT_APP_GMAPS_KEY || 'AIzaSyCqFtLXz5o1TtPVEEnEv1q6fitngwGhqoQ'
		);

		let response = await get(url + params.toString());

		if (response?.data?.results?.length && response?.data?.results?.[0]?.formatted_address)
			return response.data.results[0].formatted_address;
		return null;
	} catch (err) {
		return null;
	}
};

function getLocationLinkOnMaps(location = { latitude: '', longitude: '' }) {
	return `https://www.google.com/maps/search/?api=1&query=${location?.latitude || ''},${
		location?.longitude || ''
	}`;
}

function generateMapImageLink(center = '40.714728,-73.998672', size = '1000x400', type = 'hybrid') {
	return `https://maps.googleapis.com/maps/api/staticmap?center=${center}&size=${size}&key=${process.env.REACT_APP_GMAPS_KEY}&zoom=19&markers=anchor|${center}&maptype=${type}`;
}

function calcDist(location1, location2) {
	// Haversine Formula to calculate distance between two locations using latitude and longitude.
	let lat1 = location1.latitude || location1.lat;
	let lat2 = location2.latitude || location2.lat;

	let lon1 = location1.longitude || location1.lng;
	let lon2 = location2.longitude || location2.lng;

	lat1 = lat1 * 0.0174532925;
	lat2 = lat2 * 0.0174532925;
	lon1 = lon1 * 0.0174532925;
	lon2 = lon2 * 0.0174532925;

	let distanceLon = lon2 - lon1;
	let distanceLat = lat2 - lat1;

	let a =
		Math.pow(Math.sin(distanceLat / 2), 2) +
		Math.cos(lat1) * Math.cos(lat2) * Math.pow(Math.sin(distanceLon / 2), 2);
	let c = 2 * Math.asin(Math.sqrt(a));
	let dist = 6371 * c * 1000; // Distance in meterd
	return parseInt(dist);
}

export { generateMapImageLink, getLocationLinkOnMaps, calcDist };
