import React from 'react';
import {
	ThemeProvider as MaterialThemeProvider,
	StyledEngineProvider,
	createTheme as createMaterialTheme,
} from '@mui/material/styles';
import StylesProvider from '@mui/styles/StylesProvider';
import { ConfigProvider } from 'antd';

const theme = createMaterialTheme({
	palette: {
		primary: {
			main: '#03989e',
		},
	},
});

const ThemeProvider = ({ children }) => {
	return (
		<StyledEngineProvider injectFirst>
			<MaterialThemeProvider theme={theme}>
				<ConfigProvider
					theme={{
						token: { colorPrimary: '#03989e' },
					}}
				>
					<StylesProvider hashPriority="high">{children}</StylesProvider>
				</ConfigProvider>
			</MaterialThemeProvider>
		</StyledEngineProvider>
	);
};

export default ThemeProvider;
