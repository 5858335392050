import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

import { formattedDate, parseJsonSafe } from '../../../helpers';
import {
	getDaysForOAndM,
	getNextOAndMDate,
	groupContentsByDate,
} from '../../../helpers/OAndMHelpers';

import { getOAndMInstance } from '../../../API';

import ActiveOAndMUI from '../../presentational/DashboardUI/ActiveOAndMUI';
import ActiveOAndMInstance from './ActiveOAndMInstance';
import NewModal from '../../reusables/Modal';
import { FormModal } from 'sl-web-base';
import useLoader from '../../../helpers/useLoader';

const ActiveOAndM = (props) => {
	const state = useSelector((state) => state);
	const loader = useLoader();

	const [activeTab, setactiveTab] = useState(0);
	const [formSubmissionViewer, setFormSubmissionViewer] = useState();

	const [activeSite, setactiveSite] = useState(0);
	const [ongoingOAndMData, setongoingOAndMData] = useState(null);

	const OAndMDays = useMemo(
		() =>
			getDaysForOAndM(
				props.OAndM?.duration,
				props.OAndM?.durationUnit,
				formattedDate(props.OAndM?.startDate?.toDate?.()) || props.OAndM?.startDate,
				props.OAndM?.frequency,
				props.OAndM?.frequencyUnit
			),
		[props.OAndM]
	);

	const nextOAndMDate = useMemo(
		() =>
			OAndMDays?.length
				? getNextOAndMDate(
						OAndMDays,
						props.OAndM?.OAndMPerformedOn || [],
						props.OAndM?.OAndMInProgressOn || [],
						props.OAndM?.changedDates || {}
				  )
				: null,
		[props.OAndM, OAndMDays]
	);

	const ongoingGroupedContents = useMemo(() => {
		if (
			ongoingOAndMData &&
			(ongoingOAndMData?.photosByDates?.length || ongoingOAndMData?.notes?.length)
		)
			return groupContentsByDate(
				ongoingOAndMData?.photosByDates || [],
				ongoingOAndMData?.notes || [],
				props.OAndM?.sites
			);
		else return {};
	}, [ongoingOAndMData, props.OAndM]);

	const fetchOngoingOAndMData = async () => {
		if (props?.OAndM?.OAndMInProgressOn?.[0]) {
			let instanceId = `${props.OAndM.id}-${props?.OAndM?.OAndMInProgressOn?.[0]}`;
			loader('Loading...');
			let data = await getOAndMInstance(instanceId);
			setongoingOAndMData(data);
			loader();
		}
	};

	useEffect(() => {
		fetchOngoingOAndMData();
		console.log('OnMId', props.OAndM);
	}, []);

	const switchTab = (tabIndex = 0) => setactiveTab(tabIndex);

	const [activeOAndMInstance, setactiveOAndMInstance] = useState(undefined);
	const toggleActiveOAndMInstance = (date = undefined) => {
		if (date === undefined || activeOAndMInstance === date) setactiveOAndMInstance(undefined);
		else setactiveOAndMInstance(date);
	};

	return (
		<>
			<ActiveOAndMUI
				{...{
					user: state.user,
					OAndM: props.OAndM,
					activeTab,
					switchTab,
					toggler: props.toggler,
					nextOAndMDate,
					OAndMDays,

					activeSite,
					setactiveSite,
					toggleActiveOAndMInstance,
					ongoingOAndMData,
					ongoingGroupedContents,
				}}
			/>
			{activeOAndMInstance ? (
				<ActiveOAndMInstance
					OAndMId={props.OAndM?.id}
					OAndM={props.OAndM}
					dateString={activeOAndMInstance}
					toggler={() => toggleActiveOAndMInstance(undefined)}
					setFormSubmissionViewer={setFormSubmissionViewer}
				/>
			) : (
				''
			)}
			{formSubmissionViewer ? (
				<NewModal
					heading={`Submission: ${formSubmissionViewer.date?.toDate?.().toUTCString?.()}`}
					toggler={() => setFormSubmissionViewer(null)}
				>
					<FormModal
						formData={
							formSubmissionViewer.formData
								? parseJsonSafe(formSubmissionViewer.formData).templateJson
								: null
						}
						formValues={
							formSubmissionViewer.formData
								? parseJsonSafe(formSubmissionViewer.formData)?.values
								: null
						}
						submissionId={formSubmissionViewer.submissionId}
						readOnly
						showLoader={loader}
					/>
				</NewModal>
			) : null}
		</>
	);
};

export default ActiveOAndM;
