import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
	Accordion,
	AccordionDetails,
	AccordionSummary,
	Typography,
	Table,
	TableHead,
	TableBody,
	TableRow,
	TableCell,
	IconButton,
} from '@mui/material';
import { ExpandMore, Visibility } from '@mui/icons-material';
import { getOAndMInstance } from '../../../API';

import NewModal from '../../reusables/Modal';

import { Button } from '@mui/material';
import { ChevronRight } from '@mui/icons-material';
import { calcDist } from '../../../helpers/mapHelpers';
import useLoader from '../../../helpers/useLoader';

const ActiveOAndMInstance = (props) => {
	const [instanceData, setinstanceData] = useState(null);
	const [activeSite, setactiveSite] = useState('default');
	const loader = useLoader();

	const getInstanceData = useCallback(async () => {
		loader('Loading...');
		let OAndMInstanceId = `${props.OAndMId}-${props.dateString}`;
		let data = await getOAndMInstance(OAndMInstanceId);
		setinstanceData(data);
		loader();
	}, [props.OAndMId, props.dateString, loader]);

	const dateWiseSubmissions = useMemo(() => {
		const submissions = instanceData?.[`formdata-${activeSite || 'default'}`] ?? [];
		const data = {};

		submissions.forEach?.((sub) => {
			const dtStr = sub?.date?.toDate?.()?.toDateString?.();
			if (!data[dtStr]) data[dtStr] = [];

			data[dtStr]?.push?.(sub);
		});
		return data;
	}, [instanceData, activeSite]);

	useEffect(() => {
		if (props.OAndMId && props.dateString) getInstanceData();
	}, [props.OAndMId, props.dateString, getInstanceData]);

	return (
        (<NewModal heading={'O&M Data'} toggler={props.toggler}>
            {instanceData ? (
				<div className={'operationsandmaintenance-info'}>
					<div className={'row'}>
						<div className={'col-sm-6 p-2'}>
							Scheduled Date:{' '}
							<strong>
								{instanceData.scheduledDate?.toDate?.()?.toDateString?.()}
							</strong>
						</div>
						<div className={'col-sm-6 p-2'}>
							Performed On:{' '}
							<strong>{instanceData.createdAt?.toDate?.()?.toDateString?.()}</strong>{' '}
							to{' '}
							<strong>
								{instanceData.performedAt?.toDate?.()?.toDateString?.()}
							</strong>
						</div>
					</div>
					<br />
					{/* Navbar to navigate between multiple sites. */}
					{props.instanceData?.sites?.length > 1 ? (
						<div className={'general-navbar mb-2'}>
							<div className={'nopadding'}>
								{props.instanceData?.sites?.map(({ id, name }, index) => (
									<a
										href={'#'}
										key={index}
										onClick={(e) => {
											e.preventDefault();
											setactiveSite(id);
										}}
										className={`general-navbar-tabswitcher ${
											activeSite === id ? 'active' : ''
										}`}
									>
										{name}
									</a>
								))}
							</div>
						</div>
					) : (
						''
					)}
					<strong>Submissions</strong>
					{Object.keys(dateWiseSubmissions).map((key) => (
						<Accordion key={key}>
							<AccordionSummary expandIcon={<ExpandMore />}>
								<Typography>{key}</Typography>
							</AccordionSummary>
							<AccordionDetails>
								<div className="col-12">
									<Table>
										<TableHead>
											<TableCell>Date</TableCell>
											<TableCell>Distance From Site</TableCell>
											<TableCell>Actions</TableCell>
										</TableHead>
										<TableBody>
											{(dateWiseSubmissions[key] ?? [])?.map?.(
												(submission, index) => (
													<TableRow key={index}>
														<TableCell>
															{submission?.date
																?.toDate?.()
																?.toLocaleString()}
														</TableCell>
														<TableCell>
															{calcDist(
																props.OAndM?.location,
																submission?.location ?? {}
															)}{' '}
															Meter(s)
														</TableCell>
														<TableCell>
															<IconButton
                                                                onClick={() =>
																	props.setFormSubmissionViewer(
																		submission
																	)
																}
                                                                size="large">
																<Visibility color="primary" />
															</IconButton>
														</TableCell>
													</TableRow>
												)
											)}
										</TableBody>
									</Table>
								</div>
							</AccordionDetails>
						</Accordion>
					))}
					<div className={'text-center mt-4'}>
						<a
							href={`/rate/${props.OAndMId}/${props.OAndMId}-${props.dateString}`}
							target={'_blank'}
							rel={'noopener noreferrer'}
							title={instanceData.ratedAt ? 'View Rating' : 'Proceed To Rating'}
						>
							<Button
								endIcon={<ChevronRight />}
								color={'primary'}
								variant={'contained'}
							>
								{instanceData.ratedAt ? 'View' : 'Proceed To'} Rating
							</Button>
						</a>
					</div>
				</div>
			) : (
				'Loading Data. Please Wait.'
			)}
        </NewModal>)
    );
};

export default ActiveOAndMInstance;
