import { useCallback, useEffect, useRef } from 'react';

const useDebounce = (func, dependencies = [], timeout = 250) => {
	const timer = useRef(null);

	useEffect(() => {
		return () => clearTimeout(timer.current);
	}, [timeout, ...dependencies]);

	return useCallback(
		(...args) => {
			if (timer.current) clearTimeout(timer.current);
			timer.current = setTimeout(() => func(...args), timeout);
		},
		[timeout, ...dependencies]
	);
};

export default useDebounce;
