import React, { useCallback, useEffect, useMemo } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

import { Check, Star, Visibility } from '@mui/icons-material';
import { Button } from '@mui/material';

const OAndMTable = (props) => {
	const getDate = (date) => {
		if (props.scheduleType === 'scheduling') {
			return props.cyclesSchedule
				?.filter((cycle) => cycle.id === date)?.[0]
				?.date?.toDate?.()
				?.toDateString();
		}

		return new Date(`${date}T00:00:00Z`).toDateString();
	};

	const sortedOAndMPerformedOn = useMemo(() => {
		const getDateObj = (date) => {
			if (props.scheduleType === 'scheduling') {
				return props.cyclesSchedule
					?.filter((cycle) => cycle.id === date)?.[0]
					?.date?.toDate?.();
			}

			return new Date(`${date}T00:00:00Z`);
		};

		return props.OAndMPerformedOn.sort((a, b) => getDateObj(a) - getDateObj(b));
	}, [props.OAndMPerformedOn, props.scheduleType, props.cyclesSchedule]);

	const getInstanceId = useCallback(
		(cycleId, currIndex) => {
			let cycleIndex = -1;
			if (props.OAndM?.scheduleType === 'scheduling') {
				props.OAndM?.cyclesSchedule?.forEach((cycle, index) => {
					if (cycle.id === cycleId) {
						cycleIndex = index;
					}
				});
			} else if (props.OAndM?.scheduleType === 'none') {
				cycleIndex = currIndex;
			}

			// In case if nothing is found, just display normal sequence
			if (cycleIndex < 0) return currIndex + 1;

			return `${props.OAndM?.onmUniqueId}-${cycleIndex + 1}`;
		},
		[props.OAndM]
	);

	return props.OAndMPerformedOn?.length ? (
		<TableContainer component={Paper}>
			<Table aria-label="O And M Table">
				<TableHead>
					<TableRow>
						<TableCell align="center">#</TableCell>
						<TableCell align="center">Date</TableCell>
						<TableCell align="center">Options</TableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					{sortedOAndMPerformedOn
						?.sort((a, b) => getDate(a) - getDate(b))
						.map?.((date, index) => {
							return (
								<TableRow key={date}>
									<TableCell align="center" component="th">
										{getInstanceId(date, index)}
									</TableCell>
									<TableCell align="center" component="th">
										{getDate(date)}
									</TableCell>
									<TableCell align="center">
										{!props.OAndMRatedOn?.includes?.(date) ? (
											<Button
												onClick={() =>
													props.toggleActiveOAndMInstance(date)
												}
												color={'primary'}
											>
												<Star /> View & Rate
											</Button>
										) : (
											<>
												<Button
													onClick={() =>
														props.toggleActiveOAndMInstance(date)
													}
													color={'primary'}
												>
													<Visibility />
												</Button>
												<Check color="primary" /> Already Rated
											</>
										)}
									</TableCell>
								</TableRow>
							);
						})}
				</TableBody>
			</Table>
		</TableContainer>
	) : (
		''
	);
};

export default OAndMTable;
