import { formattedDate } from './index';

const calculateNOAndMs = (duration, durationUnit, frequency, frequencyUnit = 'Day') => {
	if (!frequency || !duration) return 0;

	let durationMultiplier = 1;
	if (durationUnit === 'Month') durationMultiplier = 30;
	if (durationUnit === 'Week') durationMultiplier = 7;
	if (durationUnit === 'Year') durationMultiplier = 365;

	let frequencyMultiplier = 1;
	if (frequencyUnit === 'Month') frequencyMultiplier = 30;
	if (frequencyUnit === 'Week') frequencyMultiplier = 7;

	return Math.ceil((duration * durationMultiplier) / (frequency * frequencyMultiplier));
};

const calculateEndOAndMDate = (duration, durationUnit, startDate) => {
	if (!duration || !startDate || duration <= 1) return new Date();

	let durationMultiplier = 1;
	if (durationUnit === 'Month') durationMultiplier = 30;
	if (durationUnit === 'Week') durationMultiplier = 7;
	if (durationUnit === 'Year') durationMultiplier = 365;

	return new Date(
		new Date(`${startDate}T00:00:00Z`).getTime() +
			(duration * durationMultiplier - 1) * (86400 * 1000)
	);
};

const getDaysForOAndM = (duration, durationUnit, startDate, frequency, frequencyUnit) => {
	if (!duration || !startDate) return [new Date()];
	let daysArray = [];

	let durationMultiplier = 1;
	if (durationUnit === 'Month') durationMultiplier = 30;
	if (durationUnit === 'Week') durationMultiplier = 7;
	if (durationUnit === 'Year') durationMultiplier = 365;

	let frequencyMultiplier = 1;
	if (frequencyUnit === 'Month') frequencyMultiplier = 30;
	if (frequencyUnit === 'Week') frequencyMultiplier = 7;

	for (let i = 0; i < duration * durationMultiplier; i += frequency * frequencyMultiplier) {
		daysArray.push(new Date(new Date(`${startDate}T00:00:00Z`).getTime() + i * (86400 * 1000)));
	}

	return daysArray;
};

const getNextOAndMDate = (
	days = [],
	OAndMPerformedOn = [],
	OAndMInProgressOn = [],
	changedDates = {}
) => {
	// days => Date Object.
	let index = -1;
	for (let day of days) {
		index++;
		let formattedDateString = formattedDate(day);
		if (OAndMInProgressOn.includes(formattedDateString))
			return changedDates[formattedDateString]
				? new Date(`${changedDates[formattedDateString]}T00:00:00Z`)
				: day;
		else if (OAndMPerformedOn.includes(formattedDateString)) continue;
		else if (day <= new Date()) {
			if (days[index + 1] && Math.abs(days[index + 1] - new Date() >= 86400 * 1000))
				return day;
			else continue;
		} else return day;
	}
	return null;
};

const groupContentsByDate = (photos = [], notes = []) => {
	let groups = {};
	for (let photo of photos) {
		if (typeof photo === 'object') {
			let photoDate = formattedDate(photo.createdAt?.toDate?.() || new Date());
			if (photoDate in groups) {
				groups[photoDate].photos.push(photo);
			} else {
				groups[photoDate] = { photos: [photo], notes: [] };
			}
		}
	}

	for (let note of notes) {
		if (typeof note === 'object') {
			let noteDate = formattedDate(note.createdAt?.toDate?.() || new Date());
			if (noteDate in groups) {
				groups[noteDate].notes.push(note);
			} else {
				groups[noteDate] = { photos: [], notes: [note] };
			}
		}
	}

	return groups;
};

export {
	calculateEndOAndMDate,
	getDaysForOAndM,
	calculateNOAndMs,
	getNextOAndMDate,
	groupContentsByDate,
};
