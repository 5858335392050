import React, { useState } from 'react';

import { Business } from '@mui/icons-material';

import DashboardUI from '../../presentational/DashboardUI';

import OAndM from './OAndM';
import { useSelector } from 'react-redux';

const Dashboard = (props) => {
	const [topRightComponent, settopRightComponent] = useState('');
	const [activeTab, setActiveTab] = useState(0);
	const user = useSelector((state) => state.user);

	const tabs = [
		{
			tabName: 'O&Ms',
			component: <OAndM settopRightComponent={settopRightComponent} />,
			tabIcon: <Business />,
		},
	];

	const switchTab = (tab) => (tab < tabs.length && tab >= 0 ? setActiveTab(tab) : null);

	return (
		<DashboardUI
			logoutUser={props.logoutUser}
			activeTab={activeTab || 0}
			tabs={tabs}
			switchTab={switchTab}
			topRightComponent={topRightComponent}
			user={user}
		/>
	);
};

export default Dashboard;
