import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { showLoader } from '../store/actionCreators';

const useLoader = () => {
	const dispatch = useDispatch();
	const setLoader = useCallback((text) => dispatch(showLoader(text)), [dispatch]);
	return setLoader;
};

export default useLoader;
